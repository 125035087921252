<template>
  <div>
    <b-row class="mt-2 match-height">
      <!-- <b-col xl="4" md="4" sm="6">
        <settings-card icon="SettingsIcon" statistic="General Settings" link="general" statistic-title="General Settings" color="secondary" />
      </b-col> -->
      <!-- <b-col xl="4" md="4" sm="6" v-show="$Can('see_permission')">
        <settings-card icon="BarChart2Icon" :statistic="$t('settings.menu.historical_price')" link="userrole" statistic-title="User Roles" color="success" />
      </b-col> -->
      <b-col xl="4" md="4" sm="6" v-if="$Can('admin_panel')">
        <settings-card icon="AirplayIcon" :statistic="$t('settings.menu.admin_panel')" link="admin-panel" statistic-title="Admin Panel" color="secondary" />
      </b-col>      

      <b-col xl="4" md="4" sm="6" v-if="$Can('admin_permissions')">
        <settings-card icon="ShieldIcon" :statistic="$t('settings.menu.role_and_permissions')" link="user-role" statistic-title="User Roles" color="success" />
      </b-col>

      <b-col xl="4" md="4" sm="6" v-if="$Can('access_personal_settings')">
        <settings-card icon="ShieldIcon" :statistic="$t('settings.menu.personal_settings')" link="personal-settings" statistic-title="User Roles" color="success" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';

import useUserList from './useSettings';

import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from './settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
  created() {
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },
};
</script>
